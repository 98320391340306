

    

    <mat-card class="card_image" style="margin-top: 50px;" >  
       
        <picture style="text-align: center;" style="margin-top: 20px;">
            <img mat-card-image *ngIf="img_path!=null" src="{{ img_path }}" alt="Logo">
            <mat-spinner style="margin-left: 45%;" diameter="60" *ngIf="uploading"></mat-spinner>

          </picture>
  <mat-card-actions >  
      <button mat-button color="warn" (click)="onClick()">  
          <mat-icon>file_upload</mat-icon>  
          {{ locale.upload_logo }}
      </button>  
     
  </mat-card-actions> 
  <button mat-raised-button color="primary" *ngIf="img_path!=null" (click)="goToMain()">  
    <mat-icon>check</mat-icon>  
    {{ locale.save }}
</button>  
  <input type="file" #fileInput id="fileUpload" name="fileUpload"  accept="image/*" style="display:none;" />

</mat-card>
