import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';
import { ApiService } from 'src/app/services/api-service.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { EndSettingsModel } from 'src/app/models/end_settings/EndSettingsModel';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-end-settings',
  templateUrl: './end-settings.component.html',
  styleUrls: ['./end-settings.component.css']
})
export class EndSettingsComponent implements OnInit {
  locale = LocaleService.Data;
  text_align='';
  text_dir='';
  end:EndSettingsModel = new EndSettingsModel();
  constructor( private apiService :ApiService,private _snackBar: MatSnackBar,private router: Router) { }

  ngOnInit(): void {
    this.getLanguage();
    this.checkToken();

  }



  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data=="") {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      })
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }



  save(){
    this.end.data.active_message = this.end.data.active_message.replace(/\n/g, " ");
    this.end.data.active_title = this.end.data.active_title.replace(/\n/g, " ");
    this.end.data.expired_message = this.end.data.expired_message.replace(/\n/g, " ");
    this.end.data.expired_title = this.end.data.expired_title.replace(/\n/g, " ");
    this.end.data.traffic_message = this.end.data.traffic_message.replace(/\n/g, " ");
    this.end.data.traffic_title = this.end.data.traffic_title.replace(/\n/g, " ");
    this.apiService.end_settings_save(this.end).subscribe(response=>{

      if (response.status = 200) {
        this.openSnackBar(this.locale.data_has_been_saved,this.locale.dismiss);
        this.router.navigate(['home/main']);
      }else{

        this.openSnackBar(this.locale.something_went_wrong,this.locale.dismiss);


      }

    })


  }

  get_user(){


    this.apiService.getUser().subscribe(response=>{
      console.log(response.data.end_settings);
      let obj = JSON.parse(response.data.end_settings);
      this.end.data = obj;


    })

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }



  checkToken() {
    if (localStorage.getItem("token") != null) {

      this.apiService.auto_login().subscribe(response => {
       // console.log(response);
        if (response.status == 200) {
          this.get_user();
        } else {
        //  console.log(response);
        }
      }, error => {
        this.router.navigate(['login-signup']);
      })
    } else {
      this.router.navigate(['login-signup']);
    }


  }

}
