import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiAdminService {


  // public server: string = 'backend/public/api/admin/';
  // public server: string = 'http://127.0.0.1:8000/api/admin/';
  public  server:string = 'https://pro-service.link/pro/sas/backend/public/api/admin/';
  encPassword = 'hH0109741663hH0109741663hH0109741663';

  constructor(private http: HttpClient, private router: Router) {
  }


  login(data: any): Observable<any> {

    return this.http.post<any>(this.server + 'admin_login', this.encrypt(data));
  }


  auto_login(): Observable<any> {

    let headers: any = this.createAuthorizationHeader();

    return this.http.get(this.server + 'auto_login', headers);
  }

  getindex(data, page = 1): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'index?page=' + page, this.encrypt(data), headers);
  }

  getLogs(data, page = 1): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'logs?page=' + page, this.encrypt(data), headers);
  }
  getUser(id): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.get<any>(this.server + 'user?id=' + id, headers);
  }

  getindex_id(id): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.get<any>(this.server + 'index_id?id=' + id, headers);
  }


  update_user(data): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'update', this.encrypt(data), headers);
  }


  activate_user(data): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'activate', this.encrypt(data), headers);
  }


  delete(data): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'delete', this.encrypt(data), headers);
  }


  encrypt(data) {

    let payload = {
      payload: CryptoJS.AES.encrypt(JSON.stringify(data), this.encPassword.trim()).toString()
    };

    return payload;

  }


  createAuthorizationHeader() {

    const headerDict = {
      'Authorization': 'Bearer ' + this.getToken()
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return requestOptions;


  }

  getToken() {

    let token = localStorage.getItem('pro_admin_token');

    if (token != null) {
      return token;
    }


    this.router.navigate(['login']);

  }


}
