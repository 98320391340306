import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiAdminService} from 'src/app/services/api-admin.service';
import {PageEvent} from '@angular/material/paginator';
import {Router, ActivatedRoute} from '@angular/router';
import {LocaleService} from 'src/app/services/locale.service';
import {ApiService} from 'src/app/services/api-service.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';


export interface PeriodicElement {
  id: number;
  action: string;
  details: string;
  user: any;
  created_at: Date;
}


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[];
  selection: any;
  resultsLength: number;
  isLoadingResults = true;
  isRateLimitReached = false;
  response: any = null;
  pageEvent: PageEvent;
  arr_selected = [];
  ItemsSelected = false;
  locale = LocaleService.Data;
  text_align = '';
  text_dir = '';

  data = {
    page: 1,
    count: 10,
    search: null
  };

  id: any = null;

  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar, private admin_api: ApiAdminService, private router: Router, private api: ApiService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id'] != null) {
        this.id = (params['id']);
      }
      this.getLanguage();
      this.checkToken();
    });


  }


  page_event(event) {

    this.data.page = event.pageIndex + 1;
    this.data.count = event.pageSize;
    this.getIndex(this.data.page);
  }


  getIndex(page = 1) {
    this.isLoadingResults = true;
    this.admin_api.getLogs(this.data, page).subscribe(response => {
      this.response = response;
      this.dataSource = new MatTableDataSource<PeriodicElement>(response.data.data);
      this.selection = new SelectionModel<PeriodicElement>(true, []);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = response.data.total;
      this.response = response;
      this.displayedColumns = ['action', 'details', 'user', 'date'];
    }, error => {

      if (error.status == 401) {
        this.router.navigateByUrl('admin');
      }


    });


  }

  getIndex_id() {
    this.isLoadingResults = true;
    this.admin_api.getindex_id(this.id).subscribe(response => {
      this.response = response;
      this.dataSource = new MatTableDataSource<PeriodicElement>(response.data.data);
      this.selection = new SelectionModel<PeriodicElement>(true, []);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = response.data.total;
      this.response = response;
      this.displayedColumns = ['action', 'details', 'user', 'date'];
    }, error => {

      if (error.status == 401) {
        this.router.navigateByUrl('admin');
      }


    });

  }

  editUser() {

    this.router.navigate(['/user', this.arr_selected[0].user.id]);

  }


  DeleteUser() {

    if (!confirm(this.locale.are_you_sure_delete)) {
      return;
    }

    this.admin_api.delete(this.arr_selected).subscribe(response => {
      if (response.status == 200) {
        this.openSnackBar(this.locale.user_deleted, this.locale.dismiss);
        this.getIndex();

      } else {
        this.openSnackBar(this.locale.something_went_wrong, this.locale.dismiss);

      }
    }, error => {

      if (error.status == 401) {
        this.router.navigateByUrl('admin');
      }


    });

  }


  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem('locale');
    if (localStorage.getItem('locale') == null) {
      localStorage.setItem('locale', 'ar');
      LocaleService.LANGUAGE = localStorage.getItem('locale');
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data == '') {
      this.api.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      });
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }


  checkToken() {
    if (localStorage.getItem('pro_admin_token') != null) {

      this.admin_api.auto_login().subscribe(response => {
        console.log(response);
        if (response.status == 200) {
          if (this.id != null) {
            this.getIndex_id();
          } else {
            this.getIndex();
          }
        } else {
          //  console.log(response);
        }
      }, error => {
        this.router.navigate(['admin']);
      });
    } else {
      this.router.navigate(['admin']);
    }

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }


  logout() {
    localStorage.clear();
    this.router.navigate(['admin']);
  }


}
