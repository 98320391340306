export class ConfirmDialogModel{

    title="";
    message="";


    constructor(title,message){

        this.title = title;
        this.message = message;

    }
    
   
}