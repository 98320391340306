import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ApiService} from 'src/app/services/api-service.service';
import {EndSettingsModel} from 'src/app/models/end_settings/EndSettingsModel';
import {LocaleService} from 'src/app/services/locale.service';
import {UserRegistrationModel} from 'src/app/models/user_registration/UserRegistrationModel';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {
  locale = LocaleService.Data;
  text_align = '';
  text_dir = '';
  registration_settings: any;

  constructor(private apiService: ApiService, private _snackBar: MatSnackBar, private router: Router) {
  }

  ngOnInit(): void {
    this.getLanguage();
    this.checkToken();

  }


  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem('locale');
    if (localStorage.getItem('locale') == null) {
      localStorage.setItem('locale', 'ar');
      LocaleService.LANGUAGE = localStorage.getItem('locale');
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data == '') {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      });
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }


  save() {

    this.apiService.update_reg_data(this.registration_settings).subscribe(response => {

      if (response.status = 200) {
        this.openSnackBar(this.locale.data_has_been_saved, this.locale.dismiss);
        this.router.navigate(['home/main']);

      } else {

        this.openSnackBar(this.locale.something_went_wrong, this.locale.dismiss);


      }

    });


  }

  get_user() {


    this.apiService.getUser().subscribe(response => {
      if (response.data.reg_settings != null) {
        console.log(response.data.reg_settings);
        let obj = JSON.parse(response.data.reg_settings);
        this.registration_settings = obj;
      }


    });

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }


  checkToken() {
    if (localStorage.getItem('token') != null) {

      this.apiService.auto_login().subscribe(response => {
        // console.log(response);
        if (response.status == 200) {
          this.get_user();
        } else {
          //  console.log(response);
        }
      }, error => {
        this.router.navigate(['login-signup']);
      });
    } else {
      this.router.navigate(['login-signup']);
    }


  }


}
