export class AdModel{
    code;
    title = 'رسالة من المدير';
    body = 'محتوى الرسالة';
    enabled = true;
    with_link = false;
    link = "google.com";
    specialize = false;
    for_active = false;
    for_expired = false;
    for_traffic = false;
}