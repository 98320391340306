export class Manager{

  id:string;
  licence:string;
  name:string;
  code:string;
  ip_in:string="http://192.168.77.77/";
  ip_out:string="http://sas4.net/";
  logo:string;
  expiration:string;
  user_package:string;
  network_name:string;
  pound_en:string = "EGP";
  pound_ar:string = "جنيه";
  auth:string;
  email:string;
  password:string;
  special_app:any;
  password_confirmation:string;


// constructor(id,licence,code,ip_in,ip_out,logo,expiration,user_package,network_name,pound_en,pound_ar,auth){

//   this.id=id;
//   this.licence=licence;
//   this.code=code;
//   this.code=code;
//   this.ip_in=ip_in;
//   this.ip_out=ip_out;
//   this.logo=logo;
//   this.expiration=expiration;
//   this.user_package=user_package;
//   this.network_name=network_name;
//   this.pound_en=pound_en;
//   this.pound_ar=pound_ar;
//   this.auth=auth;
// }


}

