<div class="container mt-5 mb-5" [ngStyle]="{'text-align':text_align,'direction':text_dir}">

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{locale.other_settings}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <form (submit)="save()" #loginForm=ngForm>


        <mat-divider [inset]="true"></mat-divider>


        <div class="row m-md-5">


          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

            <mat-slide-toggle name="settings" [(ngModel)]="settings.activate_debtor">
              <mat-label>{{locale.activate_debtor}}</mat-label>
            </mat-slide-toggle>
            <div class="alert  mt-4" role="alert">
              {{locale.activate_debtor_details}}
            </div>

          </div>

        </div>

        <div class="row m-md-5">



          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">
            <mat-slide-toggle name="print_footer" [(ngModel)]="settings.print_footer">
              <mat-label>{{locale.print_footer}}</mat-label>
            </mat-slide-toggle>
            <div class="alert  mt-4" role="alert">
              {{locale.print_footer_details}}
            </div>
          </div>

          <div *ngIf="settings.print_footer" class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">
            <mat-slide-toggle name="force_printing_footer" [(ngModel)]="settings.force_printing_footer">
              <mat-label>{{locale.force_print_footer}}</mat-label>
            </mat-slide-toggle>
            <div class="alert  mt-4" role="alert">
              {{locale.force_print_footer_details}}
            </div>
          </div>


          <div *ngIf="settings.print_footer" class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">
            <mat-form-field class="w-100">
              <mat-label>{{locale.text_you_want_to_print}}</mat-label>
              <textarea class="text-center" name="footer" matInput #active_message maxlength="100"
                        placeholder="EX. Welcome to our network ....." [(ngModel)]="settings.footer"></textarea>
              <mat-hint align="end">{{active_message.value.length}} / 100</mat-hint>
            </mat-form-field>
          </div>




        </div>


        <div class="row m-md-5">


          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

            <mat-slide-toggle name="enable_wallet_pay" [(ngModel)]="settings.enable_wallet_pay">
              <mat-label>{{locale.enable_wallet_pay}}</mat-label>
            </mat-slide-toggle>
            <div class="alert  mt-4" role="alert">
              {{locale.enable_wallet_pay_details}}
            </div>

          </div>

        </div>

        <mat-divider [inset]="true"></mat-divider>


        <div style="text-align: end; margin: 10px;">

          <button mat-flat-button color="primary" type="submit">{{locale.save}}</button>

        </div>

      </form>


    </mat-card-content>


  </mat-card>

</div>
