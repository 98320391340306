<mat-toolbar color="primary" class="navbar navbar-expand-lg  fixed-top">
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="logout()">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<div class="example-container mat-elevation-z8">
  <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      It will be reset in one minute.
    </div>
  </div>

  <div class="row" style="margin-top: 80px;">
    <div class=" col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <form (submit)="getIndex()">
        <mat-form-field class="mx-3 my-3">
          <mat-label>{{locale.search_here}}</mat-label>
          <input matInput name="search" type="search" placeholder="{{locale.keyword}}" class="search-input pl-2"
                 [(ngModel)]="data.search">
        </mat-form-field>
        <button class="mx-3 my-3" mat-raised-button color="primary" (click)="getIndex()">
          <mat-icon>search</mat-icon>
          {{locale.search}}
        </button>
      </form>
    </div>
    <div class=" col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">

      <div>

      </div>

    </div>

  </div>


  <div class="example-table-container mt-5">


    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">



      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Acction</th>
        <td mat-cell *matCellDef="let element">
          {{element.action}}
        </td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef> Details</th>
        <td mat-cell *matCellDef="let element"> {{element.details}} </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> User</th>
        <td mat-cell *matCellDef="let element" [class.link_element]="element.user">
          <mat-label *ngIf="element.user" [routerLink]="['/user',element.user.id]"
                     routerLinkActive="router-link-active">{{element.user.licence}}</mat-label>
          <mat-label *ngIf="!element.user"></mat-label>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Created At</th>
        <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>
  </div>

  <mat-paginator class="selector" [length]="resultsLength" [pageSizeOptions]="[10,50,100]" (page)="page_event($event)"
                 showFirstLastButtons></mat-paginator>

</div>
