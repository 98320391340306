<div class="container mt-5 mb-5" [ngStyle]="{'text-align':text_align,'direction':text_dir}">

    <mat-card>
        <mat-card-header>
            <mat-card-title>{{locale.end_settings}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>

            <form (submit)="save()" #loginForm=ngForm>




                <div class="row m-5">
                    <h2>{{locale.active_users}}<i class="fa fa-user-check" aria-hidden="true"></i></h2>
                    <mat-divider [inset]="true"></mat-divider>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-form-field class="example-full-width">
                            <mat-label>{{locale.message_title}}</mat-label>
                            <textarea name="active_title" matInput #active_title maxlength="256" placeholder="EX.Active user....." [(ngModel)]="end.data.active_title"></textarea>
                            <mat-hint align="end">{{active_title.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-form-field class="example-full-width">
                            <mat-label>{{locale.message_body}}</mat-label>
                            <textarea name="active_message" matInput #active_message maxlength="256" placeholder="EX. your service is ok ....." [(ngModel)]="end.data.active_message"></textarea>
                            <mat-hint align="end">{{active_message.value.length}} / 150</mat-hint>
                        </mat-form-field>

                    </div>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-slide-toggle name="active_qr" [(ngModel)]="end.data.active_qr">{{locale.show_qr}}</mat-slide-toggle>

                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-slide-toggle name="active_id" [(ngModel)]="end.data.active_id">{{locale.show_id}}</mat-slide-toggle>

                    </div>


                </div>




                <mat-divider [inset]="true"></mat-divider>



                <div class="row m-5">
                    <h2>{{locale.expired_users}} <i class="fa fa-user-clock" aria-hidden="true"></i></h2>
                    <mat-divider [inset]="true"></mat-divider>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-form-field class="example-full-width">
                            <mat-label>{{locale.message_title}}</mat-label>
                            <textarea name="expired_title" matInput #expired_title maxlength="256" placeholder="EX.expired Service....." [(ngModel)]="end.data.expired_title"></textarea>
                            <mat-hint align="end">{{expired_title.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-form-field class="example-full-width">
                            <mat-label>{{locale.message_body}}</mat-label>
                            <textarea name="expired_message" matInput #expired_message maxlength="256" placeholder="EX. Your Service has been expired ....." [(ngModel)]="end.data.expired_message"></textarea>
                            <mat-hint align="end">{{expired_message.value.length}} / 150</mat-hint>
                        </mat-form-field>

                    </div>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-slide-toggle name="expired_id" [(ngModel)]="end.data.expired_qr">{{locale.show_qr}}</mat-slide-toggle>

                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-slide-toggle name="traffic_title" [(ngModel)]="end.data.expired_id">{{locale.show_id}}</mat-slide-toggle>

                    </div>

                </div>

                <mat-divider [inset]="true"></mat-divider>



                <div class="row m-5">
                    <h2>{{locale.traffic_users}} <i class="fa fa-user-times" aria-hidden="true"></i></h2>
                    <mat-divider [inset]="true"></mat-divider>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-form-field class="example-full-width">
                            <mat-label>{{locale.message_title}}</mat-label>
                            <textarea name="traffic_message" matInput #traffic_message maxlength="256" placeholder="EX.Consumer package....." [(ngModel)]="end.data.traffic_title"></textarea>
                            <mat-hint align="end">{{traffic_message.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-form-field class="example-full-width">
                            <mat-label>{{locale.message_body}}</mat-label>
                            <textarea name="traffic_qr" matInput #traffic_qr maxlength="256" placeholder="EX. Your package has been fully consumed ....." [(ngModel)]="end.data.traffic_message"></textarea>
                            <mat-hint align="end">{{traffic_qr.value.length}} / 150</mat-hint>
                        </mat-form-field>

                    </div>

                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-slide-toggle name="traffic_id" [(ngModel)]="end.data.traffic_qr">{{locale.show_qr}}</mat-slide-toggle>

                    </div>
                    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

                        <mat-slide-toggle name="traffic_id" [(ngModel)]="end.data.traffic_id">{{locale.show_id}}</mat-slide-toggle>

                    </div>

                </div>

                <div style="text-align: end; margin: 10px;">

                    <button mat-flat-button color="primary" type="submit">{{locale.save}}</button>

                </div>

            </form>


        </mat-card-content>


    </mat-card>

</div>