<div class="container mt-5 mb-5" [ngStyle]="{'text-align':data.text_align,'direction':data.text_dir}">
    <h1 mat-dialog-title>
        {{data.locale.add_user_item}}</h1>
    <div mat-dialog-content>
        <mat-form-field [ngStyle]="{'text-align':data.text_align,'direction':data.text_dir}">
            <mat-label>{{data.locale.item_title}}</mat-label>
            <input matInput [(ngModel)]="data.data.title" required [ngStyle]="{'text-align':data.text_align,'direction':data.text_dir}">
        </mat-form-field>

        <mat-form-field [ngStyle]="{'text-align':data.text_align,'direction':data.text_dir}">
            <mat-label>{{data.locale.item_link}}</mat-label>
            <input matInput [(ngModel)]="data.data.link" required>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{data.locale.cancel}}</button>
        <button mat-button [mat-dialog-close]="data.data" cdkFocusInitial>{{data.locale.ok}}</button>
    </div>
</div>