import { ApiService } from 'src/app/services/api-service.service';
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { error } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { LocaleService } from 'src/app/services/locale.service';


@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {
  @ViewChild("fileInput", {static: false}) fileInput: ElementRef;
  locale = LocaleService.Data;

  constructor(private api:ApiService,private router:Router) { }

  ngOnInit(): void {
    this.getLanguage(false);
  }

  files = [];
  uploading = false;
  img_path = null;

  
  private upload() {  
    this.uploading = true;
    this.fileInput.nativeElement.value = '';  
    this.files.forEach(file => {  
      this.callUploadService(file);  
    });  
  }
  
  callUploadService(file) {
   
   
    file.inProgress = true;
    this.api.uploadImage(file.data).subscribe(response=> {  
          if (typeof (event) === 'object') {  
            this.img_path = response.logo
            this.uploading = false;

          }  
    }, error => {
      this.uploading = false;
        });  
    }

 
  

  onClick() {  
    this.files = [];
    const fileInput = this.fileInput.nativeElement;
    fileInput .onchange = () => {  
        for (let index = 0; index < fileInput .files.length; index++)  
        {  
             const file = fileInput .files[index];  
             this.files.push({ data: file, inProgress: false, progress: 0});  
        }  
          this.upload();  
    };  
    fileInput.click();  
  }
  

  goToMain() {
    this.router.navigate(['home']);
  }



  getLanguage(newLocale) {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");
    
  
    }
    if (LocaleService.Data=="" || newLocale) {
      this.api.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
  
      })
    }
   
   
  }






}
