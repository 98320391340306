import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LoginSignupComponent } from './components/login-signup/login-signup.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatGridListModule } from '@angular/material/grid-list';

import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { MainContentComponent } from './components/main-content/main-content.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EndSettingsComponent } from './components/end-settings/end-settings.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RequestStaticComponent } from './components/request-static/request-static.component';
import { SideBarSettingsComponent } from './components/side-bar-settings/side-bar-settings.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SideBaItemComponent } from './components/side-ba-item/side-ba-item.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { AdminLoginComponent } from './components/admin/admin-login/admin-login.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EditUserComponent } from './components/admin/edit-user/edit-user.component';
import { HotspotComponent } from './components/hotspot/hotspot.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { OtherSettingsComponent } from './components/other-settings/other-settings.component';
import { ActivateDialogComponent } from './components/admin/activate-dialog/activate-dialog.component';
import { UsersComponent } from './components/admin/users/users.component';
import { AdsComponent } from './components/ads/ads.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AddAdsComponent } from './components/dialogs/add-ads/add-ads.component';
import { SpinnerComponent } from './components/dialogs/spinner/spinner.component';
import { LogComponent } from './components/admin/log/log.component';






@NgModule({
  declarations: [
    AppComponent,
    LoginSignupComponent,
    MainPageComponent,
    UploadImageComponent,
    MainContentComponent,
    ProfileComponent,
    EndSettingsComponent,
    RequestStaticComponent,
    SideBarSettingsComponent,
    SideBaItemComponent,
    ConfirmDialogComponent,
    UserRegistrationComponent,
    AdminLoginComponent,
    UsersComponent,
    EditUserComponent,
    HotspotComponent,
    AnnouncementComponent,
    OtherSettingsComponent,
    ActivateDialogComponent,
    AdsComponent,
    AddAdsComponent,
    SpinnerComponent,
    LogComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatCardModule,
    MatExpansionModule,
    MatListModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatProgressBarModule,
    MatGridListModule,
    MatDividerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    NgxMatColorPickerModule,
    MatFormFieldModule



  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
    ,
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
  bootstrap: [AppComponent],

})
export class AppModule { }
