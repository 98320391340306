<div class="container mt-5 mb-5" [ngStyle]="{'text-align':text_align,'direction':text_dir}">

  <mat-card>
    <mat-card-header>
      <mat-card-title>{{locale.ads_settings}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>


      <div>


        <button class="mt-3" mat-flat-button color="primary" (click)="openDialog()" >{{locale.add}}</button>

        <app-spinner *ngIf="!addsData"></app-spinner>

        <div *ngFor="let add of addsData">

          <mat-accordion  multi>
              <mat-expansion-panel  class="mt-2 py-2 border border-dark">
                  <mat-expansion-panel-header>
                      <mat-panel-title>
                          {{add.title}}
                      </mat-panel-title>
                      <mat-panel-description>
                          <div style="direction: ltr;">
                              {{add.body}}
                              <mat-icon>message</mat-icon>
                          </div>

                          <div class='mx-2' style="direction: ltr;">
                            {{add.view_count}}
                            <i class="fa fa-eye" aria-hidden="true"></i>
                        </div>

                        

                      </mat-panel-description>
                  </mat-expansion-panel-header>

                 



                  <div class="alert alert-info" role="info">


                    <div class="card" style="width:fit-content">
                      <div class="card-header">
                        {{add.title}}
                      </div>
                      <div class="card-body">
                        <p class="card-text">{{add.body}}</p>
                        <div *ngIf="add.with_link" class="alert alert-primary" role="alert">
                          <strong>{{locale.to_navigate}}</strong> <a [href]="add.link" target="_blank" class="alert-link mx-2">{{locale.click_here}}</a>
                        </div>
                      </div>

                      <div class="card-footer" align="end">
                        <button mat-button >Cancel</button>
                        <button mat-button >OK</button>
                      </div>

    
                    </div>
                    
                  
                   






                      <button type="button" mat-icon-button color="warn" (click)="remove(add.id)">
                          <mat-icon>close</mat-icon>
                        </button>
                      <button mat-icon-button type="button" (click)="openDialog(add.id)">
                          <mat-icon>edit</mat-icon>
                        </button>
                  </div>



              </mat-expansion-panel>


          </mat-accordion>

      </div>




        <mat-divider [inset]="true"></mat-divider>

        <div style="text-align: end; margin: 10px;">

          <button mat-flat-button color="primary" type="button" [routerLink]="['/home/main']">{{locale.save}}</button>

        </div>

    </div>


    </mat-card-content>


  </mat-card>

</div>
