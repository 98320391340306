<div [ngStyle]="{'text-align':data.text_align,'direction':data.text_dir}">

    <h1 mat-dialog-title>
        {{data.data.title}}
    </h1>

    <div mat-dialog-content>
        <p>{{data.data.message}}</p>
    </div>

    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{data.locale.cancel}}</button>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{data.locale.ok}}</button>
    </div>

</div>