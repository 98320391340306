import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';
import { ApiService } from 'src/app/services/api-service.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { EndSettingsModel } from 'src/app/models/end_settings/EndSettingsModel';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddAdsComponent } from '../dialogs/add-ads/add-ads.component';
import { AdModel } from 'src/app/models/ad/ad_model';
import { SideBarSettings } from 'src/app/models/side_bar_settings/SideBarSettings';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/models/confirm_dialog_model/ConfirmDialogModel';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css']
})
export class AdsComponent implements OnInit {
  locale = LocaleService.Data;
  text_align='';
  text_dir='';
  userData;
  addsData = [];
  constructor( private apiService :ApiService,private _snackBar: MatSnackBar,private router: Router,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getLanguage();
    this.checkToken();

  }



  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data=="") {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      })
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }




  get_user(){


    this.apiService.getUser().subscribe(response=>{
      this.userData = response.data;
     this.getAddsList();
    })

  }

  getAddsList(){
    this.addsData = null;
  this.apiService.get_adds_list({code:this.userData.licence,for_admin:true}).subscribe(response => {
    console.log(response);
    this.addsData = response.data;
  })


  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }



  checkToken() {
    if (localStorage.getItem("token") != null) {

      this.apiService.auto_login().subscribe(response => {
       // console.log(response);
        if (response.status == 200) {
          this.get_user();
        } else {
        //  console.log(response);
        }
      }, error => {
        this.router.navigate(['login-signup']);
      })
    } else {
      this.router.navigate(['login-signup']);
    }


  }



  openDialog(id:any = false): void {
    const dialogRef = this.dialog.open(AddAdsComponent, {
      data: {data : id},
      width:'100%',
    }).afterClosed().subscribe(response=>{
      if(response === true){
        this.getAddsList();
      }
    });

  }

  remove(id){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {data : new ConfirmDialogModel(this.locale.are_you_sure,this.locale.you_will_remove_this_item) ,
         locale:this.locale,
         text_align:this.text_align,
         text_dir:this.text_dir
        
        },
      
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if (result != null) {
        this.apiService.delete_ad({id:id}).subscribe(response => {
          if (response.status === 200) {
            this.openSnackBar('Done','dimiss');
            this.getAddsList();
          }

        })
      }

    });

  }

}
