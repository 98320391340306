import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { LocaleService } from 'src/app/services/locale.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SideBarSettings } from 'src/app/models/side_bar_settings/SideBarSettings';
import { SideBaItemComponent } from '../side-ba-item/side-ba-item.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/models/confirm_dialog_model/ConfirmDialogModel';



@Component({
  selector: 'app-side-bar-settings',
  templateUrl: './side-bar-settings.component.html',
  styleUrls: ['./side-bar-settings.component.css']
})
export class SideBarSettingsComponent implements OnInit {

  locale = LocaleService.Data;
  text_align = '';
  text_dir = '';
  sidebar_settings;

  arr_side_bar = new Array<SideBarSettings>();

  constructor(private apiService: ApiService, private _snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) { }


  ngOnInit(): void {
    this.getLanguage();
    this.get_user();
  }

  openDialog(update): void {
    var dialogRef;
    if (update!=null) {
       dialogRef = this.dialog.open(SideBaItemComponent, {
        width: '280px',
        data: {data : update ,
          locale:this.locale,
          text_align:this.text_align,
          text_dir:this.text_dir
         
         },
      });
    }else{
       dialogRef = this.dialog.open(SideBaItemComponent, {
        width: '280px',
        data: {data :new SideBarSettings() ,
          locale:this.locale,
          text_align:this.text_align,
          text_dir:this.text_dir
         
         },
      });
  
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && (result.title!=null && result.link!=null)) {
        if (update!=null) {
          this.arr_side_bar[this.arr_side_bar.indexOf(update)] = result;
          return;
        }
        this.arr_side_bar.push(result);
        //this.save();


      }

    });
  }


  get_user() {

    this.arr_side_bar = [];
    this.apiService.getUser().subscribe(response => {
      let obj = JSON.parse(response.data.side_bar_settings);
      if (obj!=null) {
        this.arr_side_bar = obj;
      }

    })

  }

  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data == "") {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      })
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }






  save() {

    this.apiService.side_bar_settings(this.arr_side_bar).subscribe(response=>{

      if (response.status = 200) {
        this.openSnackBar(this.locale.data_has_been_saved,this.locale.dismiss);
        this.get_user()

      }else{

        this.openSnackBar(this.locale.something_went_wrong,this.locale.dismiss);


      }

    })


  }
  
  remove(item:SideBarSettings){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: {data : new ConfirmDialogModel(this.locale.are_you_sure,this.locale.you_will_remove_this_item) ,
         locale:this.locale,
         text_align:this.text_align,
         text_dir:this.text_dir
        
        },
      
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if (result != null) {
        this.arr_side_bar.splice(this.arr_side_bar.indexOf(item), 1);
      }

    });

  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

   openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }


  update(item){

    this.openDialog(item)

  }

}



