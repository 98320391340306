<div class="main-container"  [ngStyle]="{'text-align':text_align,'direction':text_dir}">


    <mat-card-header>
        <mat-card-title>{{locale.add_ad}}</mat-card-title>
    </mat-card-header>

    <hr>

<form (ngSubmit)="saveData()" >

  <div class="row">
   

    <div class="col-12 mt-5">

      <mat-slide-toggle  [(ngModel)]="adModel.enabled"  name="enabled">{{locale.enabled}}</mat-slide-toggle>

    </div>




</div>


<div class="row" *ngIf="adModel.enabled" >



  <div class="col-12 mt-5">

    <mat-slide-toggle (change)="checkSpecialization()" [(ngModel)]="adModel.specialize"  name="specialize">{{locale.specialize}}</mat-slide-toggle>

  </div>

  <div class="col-md-4 mt-5">

      <mat-slide-toggle *ngIf="adModel.specialize" [(ngModel)]="adModel.for_active" name="for_active">{{locale.active_users}}</mat-slide-toggle>

    </div>
    <div class="col-md-4 mt-5">

      <mat-slide-toggle *ngIf="adModel.specialize" [(ngModel)]="adModel.for_expired" name="for_expired">{{locale.expired_users}}</mat-slide-toggle>

    </div>
    <div class="col-md-4 mt-5">

      <mat-slide-toggle *ngIf="adModel.specialize" [(ngModel)]="adModel.for_traffic" name="for_traffic">{{locale.traffic_users}}</mat-slide-toggle>

    </div>

  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

    <mat-form-field class="example-full-width">
      <mat-label>{{locale.message_title}}</mat-label>
      <textarea name="title" matInput #active_title maxlength="256"
        placeholder="EX.Active user....." [(ngModel)]="adModel.title"></textarea>
      <mat-hint align="end">{{active_title.value.length}} / 100</mat-hint>
    </mat-form-field>
  </div>

  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

    <mat-form-field class="example-full-width">
      <mat-label>{{locale.message_body}}</mat-label>
      <textarea name="body" matInput #active_message maxlength="256"
        placeholder="EX. your service is ok ....." [(ngModel)]="adModel.body"></textarea>
      <mat-hint align="end">{{active_message.value.length}} / 150</mat-hint>
    </mat-form-field>

  </div>

  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

    <mat-slide-toggle name="active_id" [(ngModel)]="adModel.with_link">{{locale.add_link}}</mat-slide-toggle>

  </div>

  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 mt-5">

    <mat-form-field *ngIf="adModel.with_link" class="example-full-width">
      <mat-label>{{locale.url}}</mat-label>
      <input  [(ngModel)]="adModel.link" name="link" matInput  placeholder="EX. https://google.com">
    </mat-form-field>


  </div>





</div>


<hr>

<div style="text-align: end; margin: 10px;">
  <button class="mr-2 ml-2" mat-flat-button color="accent" [mat-dialog-close]="false">{{locale.cancel}}</button>
  <button mat-flat-button color="primary" type="submit">{{locale.save}}</button>
  
</div>



</form>

   



</div>
  