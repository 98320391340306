import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public static token: any = null;


  // public  server:string = 'backend/public/';
  // public server: string = 'http://127.0.0.1:8000/';

  public server: string = 'https://pro-service.link/pro/sas/backend/public/';
  encPassword = 'hH0109741663hH0109741663hH0109741663';

  constructor(private http: HttpClient, private router: Router) {}


  addManager(manager: any): Observable<any> {

    return this.http.post<any>(this.server + 'api/register', this.encrypt(manager));

  }


  check_licence_existed(manager: any): Observable<any> {

    return this.http.post<any>(this.server + 'api/checklicence', this.encrypt(manager));
  }

  check_mail_existed(manager: any): Observable<any> {

    return this.http.post<any>(this.server + 'api/check_mail', this.encrypt(manager));
  }

  auto_login(): Observable<any> {

    let headers: any = this.createAuthorizationHeader();

    return this.http.get(this.server + 'api/auto_login', headers);
  }

  login(data: any): Observable<any> {

    return this.http.post<any>(this.server + 'api/login', this.encrypt(data));
  }

  end_settings_save(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();

    return this.http.post<any>(this.server + 'api/end_page', this.encrypt(data), headers);
  }

  other_settings_save(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();

    return this.http.post<any>(this.server + 'api/other_settings', this.encrypt(data), headers);
  }

  update_reg_data(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();

    return this.http.post<any>(this.server + 'api/update_reg_data', this.encrypt(data), headers);
  }

  update_hots_data(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();

    return this.http.post<any>(this.server + 'api/update_hots_data', this.encrypt(data), headers);
  }

  side_bar_settings(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();

    return this.http.post<any>(this.server + 'api/update_side_bar', this.encrypt(data), headers);
  }

  insert_ad(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'api/insert_ad', this.encrypt(data), headers);
  }


  get_adds_list(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'api/get_adds', this.encrypt(data), headers);
  }

  get_ad(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'api/get_ad', this.encrypt(data), headers);
  }

  update_ad(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'api/update_ad', this.encrypt(data), headers);
  }

  delete_ad(data: any): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'api/delete_ad', this.encrypt(data), headers);
  }

  encrypt(data) {

    let payload = {
      payload: CryptoJS.AES.encrypt(JSON.stringify(data), this.encPassword.trim()).toString()
    };

    return payload;

  }


  createAuthorizationHeader() {

    const headerDict = {
      'Authorization': 'Bearer ' + this.getToken()
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return requestOptions;


  }


  getToken() {

    let token = localStorage.getItem('token');

    if (token != null) {
      return token;
    }


    this.router.navigate(['login']);

  }


  uploadImage(filedata: any): Observable<any> {

    let headers0: any = this.createAuthorizationHeader();

    var myFormData = new FormData();

    myFormData.append('image', filedata);
    return this.http.post<any>(this.server + 'api/add_logo', myFormData, headers0);
  }

  getUser(): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.get<any>(this.server + 'api/user', headers);
  }


  signOut(): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.get<any>(this.server + 'api/logout', headers);
  }

  update_data(data): Observable<any> {
    let headers: any = this.createAuthorizationHeader();
    return this.http.post<any>(this.server + 'api/update_data', this.encrypt(data), headers);
  }

  getLocale(): Observable<any> {

    return this.http.get<any>(this.server + 'api/locale');
  }

}
