<mat-drawer-container class="example-container" autosize>
    <mat-toolbar color="primary" class="example-toolbar">
        <button mat-icon-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
        <h2 class="example-app-name" *ngIf="user.name!=null">{{ user.name }}</h2>
        <h2 class="example-app-name" *ngIf="user.name==null">{{ user.email }}</h2>
        <h1 style="font-size: xx-large; text-align: center; margin-left: 20px;margin-right: 20px;">PRO</h1>

        <div class="menu">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="0">
                <mat-icon>more_vert</mat-icon>
              </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="signOut()">
                  <mat-icon>exit_to_app</mat-icon>
                  <span>{{ locale.signout }}</span>
                </button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="menu2" aria-label="">
                <mat-icon>flag</mat-icon>
              </button>



            <mat-menu #menu2="matMenu">
                <button mat-menu-item (click)="changeLanguage('ar')">
                  <mat-icon>flag</mat-icon>
                  <span>العربية</span>
                </button>
                <button mat-menu-item (click)="changeLanguage('en')">
                  <mat-icon>flag</mat-icon>
                  <span>English</span>
                </button>
            </mat-menu>

        </div>


    </mat-toolbar>
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <mat-nav-list>
            <div class="logo-nav">
                <img src="{{  user.logo }}">
            </div>

            <a mat-list-item [routerLink]="['/home/main']" (click)="drawer.toggle()"> {{ locale.home }} </a>
            <a mat-list-item [routerLink]="['/home/profile']" (click)="drawer.toggle()"> {{ locale.profile }} </a>
            <a mat-list-item [routerLink]="['/home/end-settings']" (click)="drawer.toggle()"> {{ locale.end_settings }} </a>
            <a mat-list-item [routerLink]="['/home/side-bar-settings']" (click)="drawer.toggle()"> {{ locale.user_tools }} </a>
            <a mat-list-item [routerLink]="['/home/user-registration']" (click)="drawer.toggle()"> {{ locale.user_registration_settings }} </a>
            <a mat-list-item [routerLink]="['/home/ads']" (click)="drawer.toggle()"> {{ locale.ads }} </a>
            <!-- <a mat-list-item [routerLink]="['/home/announcement']"> {{ locale.hotspot_settings }} </a> -->
             <a mat-list-item [routerLink]="['/home/other-settings']" (click)="drawer.toggle()"> {{ locale.other_settings }} </a>


        </mat-nav-list>
    </mat-drawer>

    <div class="example-sidenav-content">
        <router-outlet></router-outlet>
    </div>

</mat-drawer-container>
