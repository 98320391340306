import { Component, Inject, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';
import { ApiService } from 'src/app/services/api-service.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { EndSettingsModel } from 'src/app/models/end_settings/EndSettingsModel';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdModel } from 'src/app/models/ad/ad_model';

@Component({
  selector: 'app-add-ads',
  templateUrl: './add-ads.component.html',
  styleUrls: ['./add-ads.component.css']
})
export class AddAdsComponent implements OnInit {
  locale = LocaleService.Data;
  text_align='';
  text_dir='';
  adModel:AdModel = new AdModel();
  userData;
  id;

  constructor( private apiService :ApiService,private _snackBar: MatSnackBar, public router:Router,public dialogRef: MatDialogRef<AddAdsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data.data){
        console.log(data);
        this.id = data.data;
        this.getSingleAdd();
      }
     }

  ngOnInit(): void {
    this.getLanguage();
    this.checkToken();

  }


  getSingleAdd() {
    this.apiService.get_ad({id:this.id}).subscribe(response => {
      this.adModel = response.data;
    })
  }

  updateAdd() {
    this.apiService.update_ad({id:this.id,data:this.adModel}).subscribe(response => {
      if (response.status === 200) {
        this.openSnackBar(this.locale.data_has_been_saved,this.locale.dismiss);
        this.dialogRef.close(true);
      }
    })
  }


  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data=="") {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      })
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }




  get_user(){


    this.apiService.getUser().subscribe(response=>{
      this.userData = response.data;
    })

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }



  checkToken() {
    if (localStorage.getItem("token") != null) {

      this.apiService.auto_login().subscribe(response => {
       // console.log(response);
        if (response.status == 200) {
          this.get_user();
        } else {
        //  console.log(response);
        }
      }, error => {
        this.router.navigate(['login-signup']);
      })
    } else {
      this.router.navigate(['login-signup']);
    }


  }

  checkSpecialization(){

      this.adModel.for_expired = this.adModel.specialize;
      this.adModel.for_active = this.adModel.specialize;
      this.adModel.for_traffic = this.adModel.specialize;

  }

  saveData(){
    if (this.data.data) {
      this.updateAdd();
      return;
    }
    this.adModel.code = this.userData.licence;
    console.log(this.adModel);
    this.apiService.insert_ad(this.adModel).subscribe(response=>{

      if (response.status = 200) {
        this.openSnackBar(this.locale.data_has_been_saved,this.locale.dismiss);
        this.dialogRef.close(true);
      }else{

        this.openSnackBar(this.locale.something_went_wrong,this.locale.dismiss);


      }
    })
  }


}



