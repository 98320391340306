import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-service.service';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { LocaleService } from 'src/app/services/locale.service';



@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {
  encPassword="hH0109741663hH0109741663hH0109741663";
  locale = LocaleService.Data;

  constructor(private apiService: ApiService, private router: Router) {
    
 
   }

   path;
  ngOnInit(): void {
    console.log(this.router.url);
    let url = this.router.url.replace("/home","");
    this. path ;
    if (url=="") {
      this.path = "home/main";
    }else{
      this.path = "home/"  +  url;

    }
   
    this.getLanguage();
    this.checkToken();

    
  }
  showFiller = false;
  user: any = "";




  getUser() {
    this.router.navigate([this.path]);
    this.apiService.getUser().subscribe(response => {
      this.user = response.data;
      this.user.logo = this.apiService.server + "img/" + this.user.logo;
    });
    
  }



  signOut() {
    this.apiService.signOut().subscribe(response => {

      if (response.status == 200) {
        localStorage.removeItem("token");
        this.router.navigate(['login-signup']);
      }

    });
  }



  checkToken() {
    if (localStorage.getItem("token") != null) {

      this.apiService.auto_login().subscribe(response => {
      //  console.log(response);
        if (response.status == 200) {
    this.getUser();
        } else {
          console.log(response);
        }
      }, error => {
        this.router.navigate(['login-signup']);
      })
    } else {
      this.router.navigate(['login-signup']);
    }


  }



changeLanguage(value) {
  LocaleService.LANGUAGE = value;
  localStorage.setItem("locale", value);
  LocaleService.changeLocale(value);
  this.locale = LocaleService.Data;
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate([this.path]);
}); 
}



getLanguage() {
  LocaleService.LANGUAGE = localStorage.getItem("locale");
  if (localStorage.getItem("locale") == null) {
    localStorage.setItem("locale", "ar");
    LocaleService.LANGUAGE = localStorage.getItem("locale");
  

  }
  if (LocaleService.Data=="") {
    this.apiService.getLocale().subscribe(response => {
      LocaleService.setLocale(response);
      this.locale = LocaleService.Data;

    })
  } else {
    this.locale = LocaleService.Data;
    


  }
  
 
}


  
}
