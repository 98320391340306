<!DOCTYPE html>
<html>

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

</head>

<body class="text-center" data-gr-c-s-loaded="true">

    <div class="row">
        <div class="col col-12">
            <img class="mb-4" src="https://client.snono-systems.com/assets/img/brand/logo-vertical.png" alt="" width="72" height="72">
        </div>
        <div class="col col-12">
            <div style="width:100%;text-align: center;margin-top: 10px;">
                <mat-form-field>
                    <mat-label>{{ locale.language }}</mat-label>
                    <mat-select [(ngModel)]="selectedValue" name="language">
                        <mat-option style="text-align: center" *ngFor="let lang of langs" (onSelectionChange)="changeLanguage(lang.value)">{{lang.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col col-12">
            <form class="form-signin" (submit)="login()">

                <div class="alert alert-warning" role="alert" *ngIf="message!=null">
                    {{message}}
                </div>
                <label class="h5 mb-3 font-weight-normal">{{locale.pls_sign_in}}</label>
                <label for="inputUsername" class="sr-only">{{locale.username}}</label>
                <input [(ngModel)]="data.username" style="text-align: center;" type="text" name="username" id="inputUsername" class="form-control" placeholder="{{locale.username}}" required="" autofocus="">
                <label for="inputPassword" name="username" class="sr-only">{{locale.password}}</label>
                <input [(ngModel)]="data.password" style="text-align: center;" type="password" name="password" id="inputPassword" class="form-control" placeholder="{{locale.password}}" required="">
                <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
                <button class="btn btn-lg btn-primary btn-block" type="submit" *ngIf="!loading">{{locale.signin}}</button>
                <p class="mt-5 mb-3 text-muted">© 2019-2020</p>
            </form>
        </div>
    </div>


</body>

</html>
