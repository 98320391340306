import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ApiAdminService} from 'src/app/services/api-admin.service';
import {PageEvent} from '@angular/material/paginator';
import {Router, ActivatedRoute} from '@angular/router';
import {LocaleService} from 'src/app/services/locale.service';
import {ApiService} from 'src/app/services/api-service.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ActivateDialogComponent} from '../activate-dialog/activate-dialog.component';


export interface PeriodicElement {
  id: number;
  name: string;
  email: string;
  email_verified_at: Date;
  password: string;
  remember_token: boolean;
  code: string;
  licence: string;
  ip_in: string;
  ip_out: string;
  logo: string;
  expiration: Date;
  user_package: string;
  network_name: string;
  pound_en: string;
  pound_ar: string;
  auth: string;
  end_settings: string;
  side_bar_settings: string;
  reg_settings: string;
  created_at: Date;
  updated_at: Date;
  view: number;
  profile_status: boolean;
}


@Component({

  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[];
  selection: any;
  resultsLength: number;
  isLoadingResults = true;
  isRateLimitReached = false;
  response: any = null;
  pageEvent: PageEvent;
  arr_selected = [];
  ItemsSelected = false;
  locale = LocaleService.Data;
  text_align = '';
  text_dir = '';

  data = {
    page: 1,
    count: 10,
    search: null
  };

  id: any = null;

  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar, private admin_api: ApiAdminService, private router: Router, private api: ApiService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['id'] != null) {
        this.id = (params['id']);
      }
      this.getLanguage();
      this.checkToken();


    });


  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    this.setSelection(this.selection);
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }


  setSelection(selection) {

    this.arr_selected = selection.selected;
    this.ItemsSelected = this.arr_selected.length > 0;

  }

  page_event(event) {

    this.data.page = event.pageIndex + 1;
    this.data.count = event.pageSize;
    this.getIndex(this.data.page);
  }


  getIndex(page = 1) {
    this.isLoadingResults = true;
    this.admin_api.getindex(this.data, page).subscribe(response => {
      this.response = response;
      this.dataSource = new MatTableDataSource<PeriodicElement>(response.users.data);
      this.selection = new SelectionModel<PeriodicElement>(true, []);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = response.users.total;
      this.response = response;
      this.displayedColumns = ['select', 'status', 'name', 'licence', 'expiration'];
    }, error => {

      if (error.status == 401) {
        this.router.navigateByUrl('admin');
      }


    });


  }

  getIndex_id() {
    this.isLoadingResults = true;
    this.admin_api.getindex_id(this.id).subscribe(response => {
      this.response = response;
      this.dataSource = new MatTableDataSource<PeriodicElement>(response.users.data);
      this.selection = new SelectionModel<PeriodicElement>(true, []);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = response.users.total;
      this.response = response;
      this.displayedColumns = ['select', 'status',  'name', 'licence', 'expiration'];
    }, error => {

      if (error.status == 401) {
        this.router.navigateByUrl('admin');
      }


    });

  }


  isExpired(expiration){
    let date_now = new Date();
    var date_user = new Date(expiration);
    var expired = date_now.getTime() > date_user.getTime();
    var active = date_now.getTime() < date_user.getTime();

    if (expired) {
      return true;
    } else if (active) {
      return false;
    }

  }

  editUser() {

    this.router.navigate(['/user', this.arr_selected[0].id]);

  }


  activateUser() {

    this.openDialog();
  }

  DeleteUser() {

    if (!confirm(this.locale.are_you_sure_delete)) {
      return;
    }

    this.admin_api.delete(this.arr_selected).subscribe(response => {
      if (response.status == 200) {
        this.openSnackBar(this.locale.user_deleted, this.locale.dismiss);
        this.getIndex();

      } else {
        this.openSnackBar(this.locale.something_went_wrong, this.locale.dismiss);

      }
    }, error => {

      if (error.status == 401) {
        this.router.navigateByUrl('admin');
      }


    });

  }


  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem('locale');
    if (localStorage.getItem('locale') == null) {
      localStorage.setItem('locale', 'ar');
      LocaleService.LANGUAGE = localStorage.getItem('locale');
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data == '') {
      this.api.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      });
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }


  checkToken() {
    if (localStorage.getItem('pro_admin_token') != null) {

      this.admin_api.auto_login().subscribe(response => {
        console.log(response);
        if (response.status == 200) {
          if (this.id != null) {
            this.getIndex_id();
          } else {
            this.getIndex();
          }
        } else {
          //  console.log(response);
        }
      }, error => {
        this.router.navigate(['admin']);
      });
    } else {
      this.router.navigate(['admin']);
    }

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }


  logout() {
    localStorage.clear();
    this.router.navigate(['admin']);
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(ActivateDialogComponent, {
      width: '250px',
      data: {id: this.arr_selected[0]}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let activate_type = result[0];
        this.activate(activate_type, this.arr_selected[0].id);
        console.log(activate_type + ' For id ' + this.arr_selected[0].id);


      }
    });
  }


  activate(activate_type, id) {
    this.admin_api.activate_user({activate_type: activate_type, id: id}).subscribe(response => {

      if (response.status == 200) {

        this.openSnackBar(this.locale.manager_activated, this.locale.dismiss);
        this.getIndex();

      } else {
        console.log(response);
      }

    });


  }

}
