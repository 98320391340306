<div class="container mt-5 mb-5" [ngStyle]="{'text-align':text_align,'direction':text_dir}">

    <mat-card>
        <mat-card-header>
            <mat-card-title>{{locale.user_hotspot_settings}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>

            <form (submit)="save()" #loginForm=ngForm class="mt-5">

                <div class="row">

                    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">

                        <mat-slide-toggle name="allow_manual" [(ngModel)]="hotspot_settings.allow_new_user">{{locale.allow_new_user}}</mat-slide-toggle>


                    </div>


                    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">

                        <mat-slide-toggle name="request_phone" [(ngModel)]="hotspot_settings.allow_android_app">{{locale.android_app}}</mat-slide-toggle>

                    </div>

                    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-3">

                        <mat-form-field *ngIf="hotspot_settings.allow_android_app">
                            <mat-label>{{locale.android_app_url}}</mat-label>
                            <input matInput name="android_app_url" [(ngModel)]="hotspot_settings.android_app_url">
                        </mat-form-field>
                    </div>



                </div>




                <div class="row">

                    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">

                        <mat-slide-toggle name="allow_mac" [(ngModel)]="hotspot_settings.only_username">{{locale.static_password}}</mat-slide-toggle>


                    </div>


                    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-5">
                        <mat-checkbox name="pass_equal_mac" *ngIf="hotspot_settings.only_username" [(ngModel)]="hotspot_settings.pass_equal_user">{{locale.password_equals_username}}</mat-checkbox>
                    </div>

                    <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12 mt-4">

                        <mat-form-field *ngIf="hotspot_settings.only_username && !hotspot_settings.pass_equal_user">
                            <mat-label>{{locale.default_password}}</mat-label>
                            <input matInput name="mac_password" [(ngModel)]="hotspot_settings.default_pass">
                        </mat-form-field>


                    </div>



                </div>








                <div style="text-align: end; margin: 10px;" class="mt-3">

                    <button mat-flat-button color="primary" type="submit">{{locale.save}}</button>

                </div>





            </form>

            <a href="http://snono.org/pro/download/snono_hotspot.zip">
                <h2>{{locale.download_hotspot_bages}}</h2>
            </a>


        </mat-card-content>


    </mat-card>

</div>
