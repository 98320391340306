import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Manager} from 'src/app/models/manager/manager';
import {ApiService} from 'src/app/services/api-service.service';
import {LocaleService} from 'src/app/services/locale.service';
import {ApiAdminService} from 'src/app/services/api-admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  uid: any = null;
  manager: Manager = new Manager();
  email_existed: boolean = false;
  licence_existed: boolean = false;
  wrongInput: boolean = false;
  files = [];
  uploading = false;
  img_path = null;
  user: any = '';
  locale = LocaleService.Data;
  main_licence = '';
  main_email = '';
  text_align = '';
  text_dir = '';

  constructor(private _snackBar: MatSnackBar, private route: ActivatedRoute, private api: ApiService, private api_admin: ApiAdminService, private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.uid = (params['id']);
      this.getLanguage();
      this.checkToken();

    });
  }


  check_for_licence() {
    this.api.check_licence_existed(this.manager).subscribe(response => {
      if (response.status === 200 && this.manager.licence != this.main_licence) {
        this.licence_existed = true;
      } else {
        this.licence_existed = false;
      }
    });
  }

  checkToken() {
    if (localStorage.getItem('pro_admin_token') != null) {

      this.api_admin.auto_login().subscribe(response => {
        console.log(response);
        if (response.status == 200) {
          this.getUser();
        } else {
          //  console.log(response);
        }
      }, error => {
        this.router.navigate(['admin']);
      });
    } else {
      this.router.navigate(['admin']);
    }

  }

  getUser() {

    this.api_admin.getUser(this.uid).subscribe(response => {
      this.manager = response;
      this.user = response;
      this.manager = this.user;
      console.log(this.manager);
      this.main_licence = this.user.licence;
      this.main_email = this.user.email;
    }, error => {

      if (error.status == 401) {
        this.router.navigateByUrl('admin');
      }


    });

  }

  check_for_mail() {

    this.api.check_mail_existed(this.manager).subscribe(response => {
      if (response.status === 200 && this.manager.email !== this.main_email) {
        this.email_existed = true;
      } else {
        this.email_existed = false;
      }
    });


  }

  update() {
    this.api_admin.update_user(this.manager).subscribe(response => {
      if (response.status = 200) {
        this.openSnackBar(this.locale.data_has_been_saved, this.locale.dismiss);

        this.router.navigate(['users', this.uid]);
      } else {
        this.openSnackBar(this.locale.something_went_wrong, this.locale.dismiss);

      }
    }, error => {

      if (error.status == 401) {
        this.router.navigateByUrl('admin');
      }


    });
  }


  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem('locale');
    if (localStorage.getItem('locale') == null) {
      localStorage.setItem('locale', 'ar');
      LocaleService.LANGUAGE = localStorage.getItem('locale');
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data == '') {
      this.api.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      });
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }


}

