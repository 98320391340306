
<div class="container" [ngStyle]="{'text-align':text_align,'direction':text_dir}">
  <mat-selection-list #shoes [multiple]="false" [(ngModel)]="data.selection">
    <mat-list-option  *ngFor="let shoe of typesOfShoes; let i = index" [value]="i">
      {{shoe}}
    </mat-list-option>
  </mat-selection-list>


  <ng-container *ngIf="shoes.selectedOptions.selected[0]?.value == null; else elseTemplate">
    <p class="mt-3">
      {{locale.selected}} : {{ typesOfShoes[0] }}
    </p>
  </ng-container>
  <ng-template #elseTemplate>
    <p class="mt-3">
      {{locale.selected}} : {{ typesOfShoes[shoes.selectedOptions.selected[0]?.value] }}

    </p>
  </ng-template>



  <div mat-dialog-actions class="mt-5">
    <button mat-button  mat-dialog-close>{{locale.cancel}}</button>
    <button mat-button [mat-dialog-close]="data.selection" cdkFocusInitial>{{locale.activate}}</button>
  </div>

</div>
