<div class="container mt-5 mb-5" [ngStyle]="{'text-align':text_align,'direction':text_dir}">

    <mat-card>
        <mat-card-header>
            <mat-card-title>{{locale.user_tools}}</mat-card-title>
        </mat-card-header>
        <button class="mt-3" mat-flat-button color="primary" (click)="openDialog(null)">{{locale.add_item}}</button>
        <mat-card-content>

            <form (submit)="save()" #loginForm=ngForm class="mt-5">

                <div *ngFor="let item of arr_side_bar">

                    <mat-accordion multi>
                        <mat-expansion-panel class="mt-2">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{item.title}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    <div style="direction: ltr;">
                                        {{item.link}}
                                        <mat-icon>link</mat-icon>
                                    </div>

                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="alert alert-info" role="info">
                                <button type="button" mat-icon-button color="warn" (click)="remove(item)">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                <button mat-icon-button type="button" (click)="update(item)">
                                    <mat-icon>edit</mat-icon>
                                  </button>
                                <button mat-icon-button type="button" (click)="openInNewTab(item.link)">
                                    <mat-icon>open_in_new</mat-icon>
                                  </button>
                            </div>



                        </mat-expansion-panel>


                    </mat-accordion>

                </div>

                <div style="text-align: end; margin: 10px;" class="mt-3">

                    <button mat-flat-button color="primary" type="submit">{{locale.save}}</button>

                </div>





            </form>


        </mat-card-content>


    </mat-card>

</div>