<mat-card style="text-align: center; ">
  <div class="row justify-content-md-center">
    <div class=" col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center justify-content-md-center ">
      <mat-card-header style="text-align: center;">

        <img class="phone" src="https://client.snono-systems.com/assets/img/brand/invoice_logo.png" />
     </mat-card-header>

    </div>
    <div class=" col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <mat-card-subtitle>{{ locale.page_requests }}</mat-card-subtitle>
      <mat-card-content style="text-align: center; ">
        {{ user.view }}
      </mat-card-content>
    </div>

    <div class=" col-12">

      <mat-card-content style="text-align: center; ">
        <img src="{{  user.logo }}">
      </mat-card-content>
    </div>

    <div class=" col-12">

      <mat-label style="font-size: xx-large; color: rgba(10, 129, 10, 0.918);" *ngIf="user.profile_status=='active'">
        {{locale.active}} ({{user.expiration}})</mat-label>
      <mat-label style="font-size: xx-large; color: rgb(185, 38, 2); " *ngIf="user.profile_status=='expired'">
        {{locale.expired}} ({{user.expiration}})</mat-label>


    </div>

  </div>
</mat-card>
