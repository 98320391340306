<div class="container" id="container">

    <div class="form-container sign-up-container" id="signUp_container">
        <div style="width:100%;text-align: center;margin-top: 10px;">
            <mat-form-field>
                <mat-label>{{ locale.language }}</mat-label>
                <mat-select [(ngModel)]="selectedValue" name="language">
                    <mat-option *ngFor="let lang of langs" (onSelectionChange)="changeLanguage(lang.value)">{{lang.viewValue}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>



      <div style="height: 100vh;background-color: white;text-align: center">
        <div class="alert alert-info text-center" role="alert">
          <strong> {{locale.to_register_go_to}}</strong>
        </div>
        👇👇👇
        <br>
        <a target="_blank" href="http://ip.snono.org">http://ip.snono.org</a>
      </div>

<!--        <form action="#" class="simple-form" (submit)="signup()" #SigninForm=ngForm>-->
<!--            <h1>{{ locale.signup }}</h1>-->

<!--            <input placeholder="{{ locale.email }}" required type="email" name="email" id="email" class="form-control" [(ngModel)]="manager.email" (ngModelChange)="check_for_mail()">-->
<!--            <div style="width: 100%;" class="alert alert-danger" role="alert" *ngIf="email_existed">{{ locale.email_exists }}</div>-->
<!--            <input placeholder="{{ locale.password }}" required type="password" name="pass" id="pass" class="form-control" [(ngModel)]="manager.password">-->
<!--            <input placeholder="{{ locale.password_confirm }}" required type="password" name="pass_c" id="pass_c" class="form-control" [(ngModel)]="manager.password_confirmation">-->
<!--            <div style="width: 100%;" class="alert alert-warning" role="alert" *ngIf="manager.password!=manager.password_confirmation && manager.password_confirmation!=null ">{{ locale.pass_match }}</div>-->
<!--            <input placeholder="{{ locale.licence }}" required type="number" name="licence" id="licence" class="form-control" [(ngModel)]="manager.licence" [(ngModel)]="manager.licence" (ngModelChange)="check_for_licence()">-->
<!--            <div style="width: 100%;" class="alert alert-danger" role="alert" *ngIf="licence_existed">{{ locale.licence_existed }}</div>-->
<!--            <input placeholder="{{ locale.name }}" type="text" name="name" class="form-control" [(ngModel)]="manager.name">-->
<!--            <input required placeholder="{{ locale.enter_local }}" #ip_in="ngModel" pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/" name="ip_in" class="form-control" [(ngModel)]="manager.ip_in">-->
<!--            <div style="width: 100%;" class="alert alert-warning" role="warning" *ngIf="ip_in.invalid &&ip_in.dirty">-->
<!--                {{ locale.locale_validate }} &ndash;&gt; http://example/-->
<!--            </div>-->
<!--            <input required ngModel placeholder="{{ locale.enter_static_ip }}" #ip_out="ngModel" pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/" name="ip_out" class="form-control" [(ngModel)]="manager.ip_out">-->

<!--            &lt;!&ndash; <a>{{locale.no_static}}<a href="/request-static" style=" color: blue;">{{locale.click_to_create_static}}</a></a> &ndash;&gt;-->
<!--            <div style="width: 100%;" class="alert alert-warning" role="warning" *ngIf="ip_out.invalid &&ip_out.dirty">-->
<!--                {{ locale.static_validate }} &ndash;&gt; http://example/-->
<!--            </div>-->
<!--            <input type="text" name="user_package" class="form-control" placeholder="{{ locale.enter_user_package }}" [(ngModel)]="manager.user_package">-->
<!--            <input type="text" name="network_name" class="form-control" placeholder="{{ locale.enter_your_network_name }}" [(ngModel)]="manager.network_name">-->
<!--            <input required type="text" name="pound_en" class="form-control" placeholder="{{ locale.enter_en_currency }}" [(ngModel)]="manager.pound_en">-->
<!--            <input required type="text" name="pound_ar" class="form-control" placeholder="{{ locale.enter_ar_currency }}" [(ngModel)]="manager.pound_ar">-->

<!--            <div class="mt-2">-->
<!--                <button type="submit" [disabled]="!SigninForm.valid ||licence_existed ||manager.password!=manager.password_confirmation">Sign Up</button>-->
<!--                <button class="mobileShow ml-2 mr-2 mt-2 mb-5" (click)="loginn()" type="button">{{ locale.login }}</button>-->

<!--            </div>-->
<!--        </form>-->
    </div>
    <div class="form-container sign-in-container" id="signin_container">
        <!-- <div style="width:100%;text-align: center;margin-top: 10px;">
      <mat-form-field>
        <mat-label>Language</mat-label>
        <mat-select [(ngModel)]="lan" name="language">
          <mat-option *ngFor="let lang of langs" [value]="lang.value" (selectedValueChange)="changeLanguage()">
            {{lang.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div> -->
        <form action="#" class="simple-form" (submit)="login()" #loginForm=ngForm>
            <h1>{{ locale.login }}</h1>
            <div class="alert alert-warning" role="alert" *ngIf="wrongInput">
                {{locale.invalid_mail_or_pass}}
            </div>
            <input placeholder="{{ locale.email }}" required type="email" name="email" id="email_login" class="form-control" [(ngModel)]="data.email">

            <input placeholder="{{ locale.password }}" required type="password" name="password" id="password" class="form-control" [(ngModel)]="data.password">
            <!-- <a href="#">Forgot your password?</a> -->
            <div>
                <button type="submit" [disabled]="!loginForm.valid" (click)="login()">{{ locale.login }}</button>
                <button class="mobileShow ml-2 mr-2 mt-2" (click)="signupp()" type="button">{{ locale.signup }}</button>

            </div>

        </form>
    </div>
    <div class="overlay-container">
        <div class="overlay">
            <div class="overlay-panel overlay-left">
                <h1>{{ locale.welcome_back }}</h1>
                <button class="ghost" id="signIn" (click)="loginn()">{{ locale.login }}</button>
            </div>
            <div class="overlay-panel overlay-right">
                <h1>{{ locale.hello_manager }}</h1>
                <p>{{ locale.enter_to_start }}</p>
                <button class="ghost" id="signUp" (click)="signupp()">{{ locale.signup }}</button>
            </div>
        </div>
    </div>
</div>
