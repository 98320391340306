import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { ApiAdminService } from 'src/app/services/api-admin.service';
import { error } from 'console';
interface Langs {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  selectedValue: any;
  locale = LocaleService.Data;
  loading = false;
  message = null;
  constructor(private apiService: ApiService, private router: Router, private api_admin: ApiAdminService) { }

  ngOnInit(): void {
    this.getLanguage(false);
    if (localStorage.getItem("username") != null && localStorage.getItem("password") != null) {
      this.data.username = localStorage.getItem("username");
      this.data.password = localStorage.getItem("password");
      this.login();

    }

  }

  data = {
    username: null,
    password: null
  }

  langs: Langs[] = [
    { value: 'ar', viewValue: 'العربية' },
    { value: 'en', viewValue: 'English' },
  ];

  changeLanguage(value) {
    LocaleService.LANGUAGE = value;
    localStorage.setItem("locale", value);
    LocaleService.changeLocale(value);
    this.locale = LocaleService.Data;
  }



  getLanguage(newLocale) {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");


    }
    if (LocaleService.Data == "" || newLocale) {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
      })
    }

  }


  login() {
    this.loading = true;
    this.message = null;
    this.api_admin.login(this.data).subscribe(response => {
      this.loading = false;
      if (response.status == 200) {

        localStorage.setItem("pro_admin_token", response.token);
        localStorage.setItem("username", this.data.username);
        localStorage.setItem("password", this.data.password);
        this.router.navigate(['users']);

      }
    }, error => {
      this.loading = false;
      if (error.status == 401) {
        this.message = this.locale.wrong_data;
      } else {
        this.message = this.locale.err_connect;
      }
    })
  }



}
