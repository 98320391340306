import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';
import { ApiService } from 'src/app/services/api-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit {

  constructor(private apiService:ApiService,private router:Router) { }
  locale = LocaleService.Data;

  ngOnInit(): void {
    this.getLanguage(false);
    this.checkToken();
  }
  user: any = "";




  getUser() {
    this.apiService.getUser().subscribe(response => {
      this.user = response.data;
      this.user.logo = this.apiService.server + "img/" + this.user.logo;
    });
    
  }
  getLanguage(newLocale) {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");
    
  
    }
    if (LocaleService.Data=="" || newLocale) {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
  
      })
    } else {
      this.locale = LocaleService.Data;
    }
   
   
  }


  checkToken() {
    if (localStorage.getItem("token") != null) {

      this.apiService.auto_login().subscribe(response => {
       // console.log(response);
        if (response.status == 200) {
          this.getUser();
        } else {
        //  console.log(response);
        }
      }, error => {
        this.router.navigate(['login-signup']);
      })
    } else {
      this.router.navigate(['login-signup']);
    }


  }

}
