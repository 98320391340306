

    <mat-card class="card_image" style="margin-top: 50px;" >

        <picture style="text-align: center;" style="margin-top: 20px;">
            <img mat-card-image *ngIf="user.logo==null && img_path!=null " src="{{ img_path }}" alt="Logo">
            <img mat-card-image *ngIf="user.logo!=null && img_path==null " src="{{user.logo }}" alt="Logo">
            <img mat-card-image *ngIf="user.logo!=null && img_path!=null " src="{{ img_path }}" alt="Logo">

            <mat-spinner style="margin-left: 45%;" diameter="60" *ngIf="uploading"></mat-spinner>

          </picture>
  <mat-card-actions >
      <button mat-button color="warn" (click)="onClick()">
          <mat-icon>file_upload</mat-icon>
          {{ locale.upload_logo }}
      </button>

  </mat-card-actions>
  <button mat-raised-button color="primary" *ngIf="img_path!=null" (click)="goToMain()">
    <mat-icon>check</mat-icon>
    {{ locale.save }}
</button>
  <input type="file" #fileInput id="fileUpload" name="fileUpload"  accept="image/*" style="display:none;" />

</mat-card>
<mat-expansion-panel class="mt-5 mb-5">
    <mat-expansion-panel-header>
     {{locale.change_acc_data}}
    </mat-expansion-panel-header>

    <form action="#" class="simple-form"(submit)="update()" #SigninForm=ngForm >
        <h1>{{ locale.update_account }}</h1>
<!--        <input  placeholder="{{ locale.licence }}" required type="number" name="licence" id="licence" class="form-control" [(ngModel)]="manager.licence" (ngModelChange)="check_for_licence()">-->
<!--        <div style="width: 100%;" class="alert alert-danger" role="alert" *ngIf="licence_existed" >{{ locale.licence_existed }}</div>-->
        <input placeholder="Name" type="text" name="name"  class="form-control"
        [(ngModel)]="manager.name" >
        <input required placeholder="{{ locale.enter_local }}"
        #ip_in="ngModel"
        pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/"
        name="ip_in"  class="form-control"
        [(ngModel)]="manager.ip_in" >
        <div style="width: 100%;" class="alert alert-warning" role="warning" *ngIf="ip_in.invalid &&ip_in.dirty" >
         {{ locale.locale_validate }} --> http://example/
         </div>
         <input required ngModel placeholder="{{ locale.enter_static_ip }}"
         #ip_out="ngModel"
         pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/"
           name="ip_out"  class="form-control"
         [(ngModel)]="manager.ip_out" >
         <div style="width: 100%;" class="alert alert-warning" role="warning" *ngIf="ip_out.invalid &&ip_out.dirty" >
           {{ locale.static_validate }} --> http://example/
           </div>
           <input  type="text" name="user_package"  class="form-control" placeholder="{{ locale.enter_user_package }}"
           [(ngModel)]="manager.user_package">
           <input  type="text" name="network_name"  class="form-control" placeholder="{{ locale.enter_your_network_name }}"
           [(ngModel)]="manager.network_name" >
           <input required type="text" name="pound_en"  class="form-control" placeholder="{{ locale.enter_en_currency }}"
           [(ngModel)]="manager.pound_en" >
           <input required type="text" name="pound_ar"  class="form-control" placeholder="{{ locale.enter_ar_currency }}"
           [(ngModel)]="manager.pound_ar" >


  <div class="mt-2" style="text-align: center;">

    <button mat-raised-button color="warn" style="width: 50%;" type="submit" [disabled]="!SigninForm.valid ||(licence_existed) ||manager.password!=manager.password_confirmation">{{ locale.update }}</button>

  </div>
    </form>

  </mat-expansion-panel>
