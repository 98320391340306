<!DOCTYPE html>
<html [ngStyle]="{'text-align':text_align,'direction':text_dir}">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>{{ locale.update_account }}</title>
</head>

<body>

    <mat-card class="my-5" [ngStyle]="{'text-align':text_align,'direction':text_dir}">


        <form action="#" class="simple-form" (submit)="update()" #SigninForm=ngForm>
            <h1>{{ locale.update_account }}</h1>

          <div>
            <mat-slide-toggle [(ngModel)]="manager.special_app"  name="special_app">Special App</mat-slide-toggle>
          </div>

            <label>{{ locale.email }}</label>
            <input dir="ltr" placeholder="{{ locale.email }}" required type="email" name="email" id="email" class="form-control" [(ngModel)]="manager.email" (ngModelChange)="check_for_mail()">
            <div style="width: 100%;" class="alert alert-danger" role="alert" *ngIf="email_existed">{{ locale.email_exists }}</div>

            <label>{{ locale.password }}</label>
            <input dir="ltr" placeholder="{{ locale.password }}" required type="password" name="pass" id="pass" class="form-control" [(ngModel)]="manager.password">

            <label>{{ locale.licence }}</label>
            <input dir="ltr" placeholder="{{ locale.licence }}" required type="number" name="licence" id="licence" class="form-control" [(ngModel)]="manager.licence" (ngModelChange)="check_for_licence()">
            <div style="width: 100%;" class="alert alert-danger" role="alert" *ngIf="licence_existed">{{ locale.licence_existed }}</div>


            <label>{{ locale.expiration }}</label>
            <input dir="ltr" placeholder="{{ locale.expiration }}" required type="date" name="expiration" id="expiration" class="form-control" [(ngModel)]="manager.expiration">



            <label>{{ locale.name }}</label>
            <input dir="ltr" placeholder="Name" placeholder="{{ locale.name }}" type="text" name="name" class="form-control" [(ngModel)]="manager.name">

            <label>{{ locale.enter_local }}</label>
            <input dir="ltr" required placeholder="{{ locale.enter_local }}" #ip_in="ngModel" pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/" name="ip_in" class="form-control" [(ngModel)]="manager.ip_in">
            <div style="width: 100%;" class="alert alert-warning" role="warning" *ngIf="ip_in.invalid &&ip_in.dirty">
                {{ locale.locale_validate }} --> http://example/
            </div>

            <label>{{ locale.enter_static_ip }}</label>
            <input dir="ltr" required ngModel placeholder="{{ locale.enter_static_ip }}" #ip_out="ngModel" pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/" name="ip_out" class="form-control" [(ngModel)]="manager.ip_out">
            <div style="width: 100%;" class="alert alert-warning" role="warning" *ngIf="ip_out.invalid &&ip_out.dirty">
                {{ locale.static_validate }} --> http://example/
            </div>

            <label>{{ locale.enter_user_package }}</label>
            <input dir="ltr" type="text" name="user_package" class="form-control" placeholder="{{ locale.enter_user_package }}" [(ngModel)]="manager.user_package">

            <label>{{ locale.enter_your_network_name }}</label>
            <input dir="ltr" type="text" name="network_name" class="form-control" placeholder="{{ locale.enter_your_network_name }}" [(ngModel)]="manager.network_name">

            <label>{{ locale.enter_en_currency }}</label>
            <input dir="ltr" required type="text" name="pound_en" class="form-control" placeholder="{{ locale.enter_en_currency }}" [(ngModel)]="manager.pound_en">

            <label>{{ locale.enter_ar_currency }}</label>
            <input dir="ltr" required type="text" name="pound_ar" class="form-control" placeholder="{{ locale.enter_ar_currency }}" [(ngModel)]="manager.pound_ar">


            <div class="mt-2" style="text-align: center;">

                <button mat-raised-button color="warn" style="width: 50%;" type="submit" [disabled]="!SigninForm.valid ||(licence_existed)">{{ locale.update }}</button>

            </div>
        </form>
    </mat-card>
</body>

</html>
