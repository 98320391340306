import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { LocaleService } from 'src/app/services/locale.service';
import { HotspotModel } from 'src/app/models/hotspot_model/HotspotModel';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {
  locale = LocaleService.Data;
  text_align='';
  text_dir='';
 hotspot_settings:HotspotModel = new HotspotModel();
  constructor( private apiService :ApiService,private _snackBar: MatSnackBar,private router: Router) { }

  ngOnInit(): void {
    this.getLanguage();
    this.checkToken();

  }



  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data=="") {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      })
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }



  save(){
    console.log(this.hotspot_settings);

    this.apiService.update_hots_data(this.hotspot_settings).subscribe(response=>{

      if (response.status = 200) {
        this.openSnackBar(this.locale.data_has_been_saved,this.locale.dismiss);
        this.router.navigate(['home/main']);

      }else{

        this.openSnackBar(this.locale.something_went_wrong,this.locale.dismiss);


      }

    })


  }

  get_user(){


    this.apiService.getUser().subscribe(response=>{
      if (response.data.hotspot_settings!=null) {
        console.log(response.data.hotspot_settings);
        let obj = JSON.parse(response.data.hotspot_settings);
        this.hotspot_settings = obj;
      }


    })

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }



  checkToken() {
    if (localStorage.getItem("token") != null) {

      this.apiService.auto_login().subscribe(response => {
       // console.log(response);
        if (response.status == 200) {
          this.get_user();
        } else {
        //  console.log(response);
        }
      }, error => {
        this.router.navigate(['login-signup']);
      })
    } else {
      this.router.navigate(['login-signup']);
    }


  }


}
