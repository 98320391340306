import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api-service.service';
import { LocaleService } from 'src/app/services/locale.service';
export interface DialogData {
  selection: any;
}
@Component({
  selector: 'app-activate-dialog',
  templateUrl: './activate-dialog.component.html',
  styleUrls: ['./activate-dialog.component.css']
})

export class ActivateDialogComponent implements OnInit {
  locale = LocaleService.Data;
  text_align = '';
  text_dir = '';
  typesOfShoes: string[] = [ this.locale.activate_3_month , this.locale.activate_1_month, '+6 Months', '+1 YEAR'];

  constructor(private api: ApiService,public dialogRef: MatDialogRef<ActivateDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    data.selection=[0];
  }

  ngOnInit(): void {
    this.getLanguage();
  }


  onSelect(){}


  getLanguage() {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;

    }
    if (LocaleService.Data == "") {
      this.api.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
        this.text_align = LocaleService.text_align;
        this.text_dir = LocaleService.text_dir;
      })
    } else {
      this.locale = LocaleService.Data;
      this.text_align = LocaleService.text_align;
      this.text_dir = LocaleService.text_dir;


    }


  }

}
