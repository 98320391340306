import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  public static LANGUAGE: string = "";
  public static Data_ar: any = "";
  public static Data_en: any = "";
  public static Data: any = "";
  public static text_align: any = 'left';
  public static text_dir: any = 'ltr';


  public static setLocale(data) {
    LocaleService.Data_ar = data.ar;
    LocaleService.Data_en = data.en;
    if (LocaleService.LANGUAGE == "en") {
      LocaleService.Data = LocaleService.Data_en;
      LocaleService.text_align = 'left';
      LocaleService.text_dir = 'ltr';
    } else {
      LocaleService.Data = LocaleService.Data_ar;
      LocaleService.text_align = 'right';
      LocaleService.text_dir = 'rtl';
    }

  }
  public static changeLocale(locale) {
    LocaleService.LANGUAGE = locale;
    if (locale == "en") {
      LocaleService.Data = LocaleService.Data_en;
      LocaleService.text_align = 'left';
      LocaleService.text_dir = 'ltr';
    } else {
      LocaleService.Data = LocaleService.Data_ar;
      LocaleService.text_align = 'right';
      LocaleService.text_dir = 'rtl';


    }
  }
  constructor() { }
}
