<mat-toolbar color="primary" class="navbar navbar-expand-lg  fixed-top">
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="logout()">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<div class="example-container mat-elevation-z8">
  <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      It will be reset in one minute.
    </div>
  </div>

  <div class="row" style="margin-top: 80px;">
    <div class=" col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <form (submit)="getIndex()">
        <mat-form-field class="mx-3 my-3">
          <mat-label>{{locale.search_here}}</mat-label>
          <input matInput name="search" type="search" placeholder="{{locale.keyword}}" class="search-input pl-2"
                 [(ngModel)]="data.search">
        </mat-form-field>
        <button class="mx-3 my-3" mat-raised-button color="primary" (click)="getIndex()">
          <mat-icon>search</mat-icon>
          {{locale.search}}
        </button>
      </form>
    </div>
    <div class=" col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">

      <div>
        <button class="mx-3 my-3" [matMenuTriggerFor]="menu" mat-raised-button color="primary">{{locale.actions}}
          <mat-icon>settings</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [disabled]="arr_selected.length!=1" (click)="activateUser()">
            <label>{{locale.activate}}</label>
            <mat-icon>bolt</mat-icon>
          </button>
          <button mat-menu-item [disabled]="arr_selected.length!=1" (click)="editUser()"><label>{{locale.edit}}</label>
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-menu-item [disabled]="arr_selected.length==0" (click)="DeleteUser()">
            <label>{{locale.delete}}</label>
            <mat-icon>delete</mat-icon>
          </button>

        </mat-menu>
      </div>

    </div>

  </div>


  <div class="example-table-container mt-5">


    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" *ngIf="response!=null">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status</th>
        <td mat-cell *matCellDef="let element">
            <div [class.expired]="isExpired(element.expiration)" [class.active]="!isExpired(element.expiration)"></div>
        </td>
      </ng-container>


      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element" class="link_element">
          <mat-label [routerLink]="['/user',element.id]"
                     routerLinkActive="router-link-active">{{element.name ? element.name : '--------'}}</mat-label>
        </td>
      </ng-container>

      <ng-container matColumnDef="licence" >
        <th mat-header-cell *matHeaderCellDef> License</th>
        <td mat-cell *matCellDef="let element" [class.bg-warning]="element.special_app==1"> {{element.licence}} </td>
      </ng-container>

      <ng-container matColumnDef="expiration">
        <th mat-header-cell *matHeaderCellDef> Expiration</th>
        <td mat-cell *matCellDef="let element"> {{element.expiration}} </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>
  </div>

  <mat-paginator class="selector" [length]="resultsLength" [pageSizeOptions]="[10,50,100]" (page)="page_event($event)"
                 showFirstLastButtons></mat-paginator>

</div>
