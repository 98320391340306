import { ApiService } from 'src/app/services/api-service.service';
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { error } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { Manager } from 'src/app/models/manager/manager';
import { LocaleService } from 'src/app/services/locale.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild("fileInput", {static: false}) fileInput: ElementRef;

  constructor(private api:ApiService,private router:Router) { }

  ngOnInit(): void {
    this.getLanguage(false);
    this.checkToken();
  }
  manager:Manager=new Manager();
  email_existed:boolean=false;
  licence_existed:boolean=false;
  wrongInput:boolean=false;
  files = [];
  uploading = false;
  img_path = null;
  user: any= "";
  locale = LocaleService.Data;
  main_licence = "";






  getUser() {
    this.api.getUser().subscribe(response => {
      this.user = response.data;
      this.user.logo = this.api.server + "img/" + this.user.logo;
      this.manager = this.user;
      this.main_licence=this.user.licence;
    });

  }



  update(){
    this.api.update_data(this.manager).subscribe(response=>{
      if(response.status === 200){
        this.user = response.data;
        this.manager = this.user;
        this.router.navigate(['home/main']);
      }

    },error=>console.log(error.error.message));
  }

  // check_for_mail(){

  //   this.api.check_mail_existed(this.manager).subscribe(response => {
  //       if (response.status === 200 && this.manager.licence!==this.user.licence)
  //       this.email_existed = true;
  //     else
  //       this.email_existed = false;
  //   })


  // }

  check_for_licence(){
    this.api.check_licence_existed(this.manager).subscribe(response=>{
      if (response.status === 200 && this.manager.licence!=this.main_licence)this.licence_existed=true;else this.licence_existed=false;
    })
  }










  private upload() {
    this.uploading = true;
    this.fileInput.nativeElement.value = '';
    this.files.forEach(file => {
      this.callUploadService(file);
    });
  }


  callUploadService(file) {


    file.inProgress = true;
    this.api.uploadImage(file.data).subscribe(response=> {
          if (typeof (event) === 'object') {
            this.img_path = response.logo
            this.uploading = false;

          }
    }, error => {
      this.uploading = false;
        });
    }




  onClick() {
    this.files = [];
    const fileInput = this.fileInput.nativeElement;
    fileInput .onchange = () => {
        for (let index = 0; index < fileInput .files.length; index++)
        {
             const file = fileInput .files[index];
             this.files.push({ data: file, inProgress: false, progress: 0});
        }
          this.upload();
    };
    fileInput.click();
  }


  goToMain() {
    this.router.navigate(['home/main']);
  }


  getLanguage(newLocale) {
    LocaleService.LANGUAGE = localStorage.getItem("locale");
    if (localStorage.getItem("locale") == null) {
      localStorage.setItem("locale", "ar");
      LocaleService.LANGUAGE = localStorage.getItem("locale");


    }
    if (LocaleService.Data=="" || newLocale) {
      this.api.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;

      })
    }


  }

  checkToken() {
    if (localStorage.getItem("token") != null) {

      this.api.auto_login().subscribe(response => {
       // console.log(response);
        if (response.status == 200) {
    this.getUser();
        } else {
        //  console.log(response);
        }
      }, error => {
        this.router.navigate(['login-signup']);
      })
    } else {
      this.router.navigate(['login-signup']);
    }


  }



}
