import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SideBarSettings } from 'src/app/models/side_bar_settings/SideBarSettings';

@Component({
  selector: 'app-side-ba-item',
  templateUrl: './side-ba-item.component.html',
  styleUrls: ['./side-ba-item.component.css']
})
export class SideBaItemComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SideBaItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
