export class EndSettingsModel{
data={
    active_title:"",
    active_message:"",
    active_qr:false,
    active_id:false,
    expired_title:"",
    expired_message:"",
    expired_qr:false,
    expired_id:false,
    traffic_title:"",
    traffic_message:"",
    traffic_qr:false,
    traffic_id:false,

}

    
  }