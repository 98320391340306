import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginSignupComponent} from './components/login-signup/login-signup.component';
import {MainPageComponent} from './components/main-page/main-page.component';
import {UploadImageComponent} from './components/upload-image/upload-image.component';
import {ProfileComponent} from './components/profile/profile.component';
import {MainContentComponent} from './components/main-content/main-content.component';
import {EndSettingsComponent} from './components/end-settings/end-settings.component';
import {RequestStaticComponent} from './components/request-static/request-static.component';
import {SideBarSettingsComponent} from './components/side-bar-settings/side-bar-settings.component';
import {UserRegistrationComponent} from './components/user-registration/user-registration.component';
import {AdminLoginComponent} from './components/admin/admin-login/admin-login.component';
import {EditUserComponent} from './components/admin/edit-user/edit-user.component';
import {HotspotComponent} from './components/hotspot/hotspot.component';
import {AnnouncementComponent} from './components/announcement/announcement.component';
import {OtherSettingsComponent} from './components/other-settings/other-settings.component';
import {UsersComponent} from './components/admin/users/users.component';
import {AdsComponent} from './components/ads/ads.component';
import {LogComponent} from './components/admin/log/log.component';

const routes: Routes = [


  {
    path: '',
    redirectTo: 'login-signup',
    pathMatch: 'full',


  }, {
    path: 'login-signup',
    component: LoginSignupComponent
  },

  {
    path: 'home',
    component: MainPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'main', pathMatch: 'full'
      },
      {
        path: 'user-registration',
        component: UserRegistrationComponent
      },
      {
        path: 'side-bar-settings',
        component: SideBarSettingsComponent,

      },
      {
        path: 'main',
        component: MainContentComponent,

      },

      {
        path: 'upload-image',
        component: UploadImageComponent,


      },
      {
        path: 'end-settings',
        component: EndSettingsComponent,

      },
      {
        path: 'ads',
        component: AdsComponent,

      },
      {
        path: 'profile',
        component: ProfileComponent,

      }
      ,
      {
        path: 'hotspot',
        component: HotspotComponent,

      },
      {
        path: 'announcement',
        component: AnnouncementComponent,

      },
      {
        path: 'other-settings',
        component: OtherSettingsComponent,

      }
    ]
  },

  {
    path: 'admin',
    component: AdminLoginComponent

  },
  {
    path: 'users/:id',
    component: UsersComponent

  },
  {
    path: 'users',
    component: UsersComponent

  },
  {
    path: 'log',
    component: LogComponent

  },
  {
    path: 'user/:id',
    component: EditUserComponent

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],//
  exports: [RouterModule]
})
export class AppRoutingModule {
}
