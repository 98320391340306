import {Component, OnInit} from '@angular/core';
import {Manager} from 'src/app/models/manager/manager';
import {ApiService} from 'src/app/services/api-service.service';
import {Router} from '@angular/router';
import {error} from 'protractor';
import {from} from 'rxjs';
import {LocaleService} from 'src/app/services/locale.service';

interface Langs {
  value: string;
  viewValue: string;
}

function signupp() {
  var container = document.getElementById('container');

  container.classList.add('right-panel-active');
  setTimeout(function() {
    scroll('signUp_container');
  }, 500);


}

function scroll(id) {
  var element = document.getElementById(id);

  element.scrollIntoView({behavior: 'smooth', block: 'end'});
}

function loginn() {
  var container = document.getElementById('container');
  container.classList.remove('right-panel-active');
  setTimeout(function() {
    scroll('signin_container');
  }, 1000);


}

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.css']
})
export class LoginSignupComponent implements OnInit {

  selectedValue: any;
  locale = LocaleService.Data;
  data = {
    email: null,
    password: null
  };
  manager: Manager = new Manager();
  email_existed: boolean = false;
  licence_existed: boolean = false;
  wrongInput: boolean = false;


  constructor(private apiService: ApiService, private router: Router) {
  }

  ngOnInit(): void {
    this.getLanguage(false);

    if (localStorage.getItem('token') != null) {

      this.apiService.auto_login().subscribe(response => {
        if (response.status == 200) {
          this.router.navigate(['home']);
        } else if (response.status == 401) {
          this.router.navigate(['login']);
        } else {
          console.log(response);
        }
      });
    }


  }

  signupp() {
    signupp();
  }

  loginn() {
    loginn();
  }


  signup() {
    this.apiService.addManager(this.manager).subscribe(response => {
      if (response.status === 200) {
        localStorage.setItem('token', response.token);
        this.router.navigate(['home', 'upload-image']);
      }
    }, error => console.log(error.error.message));
  }

  check_for_mail() {

    this.apiService.check_mail_existed(this.manager).subscribe(response => {
      if (response.status === 200) {
        this.email_existed = true;
      } else {
        this.email_existed = false;
      }
    });


  }

  check_for_licence() {
    this.apiService.check_licence_existed(this.manager).subscribe(response => {
      if (response.status === 200) {
        this.licence_existed = true;
      } else {
        this.licence_existed = false;
      }
    });
  }


  login() {

    this.apiService.login(this.data).subscribe(response => {
      localStorage.setItem('token', response.token);
      this.router.navigate(['home']);
      this.wrongInput = false;
    }, error => {
      if (error.status == 401) {
        this.wrongInput = true;
      }
    });

  }


  langs: Langs[] = [
    {value: 'ar', viewValue: 'العربية'},
    {value: 'en', viewValue: 'English'},
  ];

  changeLanguage(value) {
    LocaleService.LANGUAGE = value;
    localStorage.setItem('locale', value);
    LocaleService.changeLocale(value);
    this.locale = LocaleService.Data;
  }


  getLanguage(newLocale) {
    LocaleService.LANGUAGE = localStorage.getItem('locale');
    if (localStorage.getItem('locale') == null) {
      localStorage.setItem('locale', 'ar');
      LocaleService.LANGUAGE = localStorage.getItem('locale');


    }
    if (LocaleService.Data == '' || newLocale) {
      this.apiService.getLocale().subscribe(response => {
        LocaleService.setLocale(response);
        this.locale = LocaleService.Data;
      });
    }


  }

}
